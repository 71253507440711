$primary-font: 'Poppins', sans-serif !important;

.form-wrapper{
    .image-preview-wrapper{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #ccc;
        b{
            display: none;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        img[src=""] {
            display: none;
        }
    }

    .image-gallery-wrapper{
        b{
            font-family: $primary-font;
        }
        .image-gallery{
            border-radius: 10px;
            margin-bottom: 10px;
            box-shadow: inset 0px -4px 2px 0px #ccc;
            border: 1px solid rgba(0, 0, 0, 0.23);
            padding-bottom: 10px;
            .image-gallery-content{
                .image-gallery-slide-wrapper{
                    .image-gallery-icon{
                        padding: 0;
                        margin: 0;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 8px rgb(131 131 131 / 15%);
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        bottom: -68px !important;
                        filter: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: unset;
                        transform: none;
                        svg{
                            width: 75%;
                            height: 75%;
                            color: rgba(0, 0, 0, 0.4);
                        }

                        &:hover{
                            color: #fff;
                            svg{
                                transform: none;
                            }
                        }

                        @media screen and (max-width: $screen_540px){
                            width: 40px;
                            height: 40px;
                            svg{
                                width: 85%;
                                height: 85%;
                            }
                        }
                    }
                }

                
                    
                    .image-gallery-slides{
                        margin-bottom: 20px;
                        .image-gallery-slide{
                            h3{
                                font-family: $primary-font;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(0, 0, 0, 0.5);
                                text-align: left;
                                margin: 0 0 30px 0;
                                border-radius: 10px;
                                box-shadow: inset 0px -4px 2px 0px #ccc;
                                padding: 10px 8px;
                                text-align: center;
                                white-space: pre-wrap;
                                word-wrap: break-word;
                                word-break: break-all;
                                display: flex;
                                justify-content: center;
                                span{
                                    flex: 0 0 85px;
                                    text-align: left;
                                }
                                a{
                                    white-space: pre-wrap;
                                    word-wrap: break-word;
                                    word-break: break-all;
                                    text-align: left;
                                }
                            }
                            .image-gallery-image{
                                width: 100%;
                                height: 100px;
                                object-fit: contain;
                                @media screen and (max-width: $screen_540px){
                                    width: 100%;
                                    height: 100px;
                                }
                            }
                        }
                    }
                

                .image-gallery-thumbnails-wrapper{
                    .image-gallery-thumbnails{
                        .image-gallery-thumbnails-container{
                            .image-gallery-thumbnail{
                                width: 52px;
                                height: 52px;
                                border-radius: 50%;
                                padding: 8px;

                                .image-gallery-thumbnail-inner{
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    img{
                                        width: 80%;
                                        height: 80%;
                                        object-fit: fill;
                                    }
                                }

                                &.active, &:hover{
                                    background: #FFFFFF;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .media-link{
        margin: 25px 0;
        a{
            word-wrap: break-word;
        }
    }
}

div[role="tooltip"]{
    @media screen and (max-width: $screen_540px){
        transform: translate3d(10px, 369px, 0px) !important;
        width: 94.5%;
        .MuiGridPanel-paper{
            .MuiGridPanelWrapper-root{
                .MuiGridPanelContent-root{
                    .MuiGridFilterForm-root{
                        flex-direction: column;
                        > .MuiFormControl-root {
                            width: 100% !important;
                            margin-bottom: 10px;
                            .MuiIconButton-label{
                                justify-content: flex-end;
                            }
                            &.MuiGridFilterForm-closeIcon{
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.download-btn{
    @media screen and (max-width: $screen_540px){
        font-size: 12px !important;
        padding: 4px 6px !important;
    }
}