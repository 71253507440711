.login-page-wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiPaper-root{
        width: 25%;
        height: auto;
        padding: 35px;
        box-sizing: border-box;
        box-shadow: 0 0 2px 0 #ccc;

        .MuiTypography-root{
            font-weight: bold;
        }

        br{
            display: none;
        }

        .MuiGrid-root{
            width: 100%;
            margin: 0;
            padding: 0;

            .MuiGrid-item{
                margin-top: 30px;
                .MuiFormControl-root{
                    width: 100%;
                }

                .MuiButtonBase-root{
                    padding: 10px 16px;
                    margin-top: 30px;
                }
            }   
        }

        @media screen and (max-width: $screen_1280px){
            width: 30%;
        }
        @media screen and (max-width: $screen_1024px){
            width: 40%;
        }
        @media screen and (max-width: $screen_768px){
            width: 60%;
        }
        @media screen and (max-width: $screen_540px){
            width: 90%;
        }
    }

    &.user-login-page, &.admin-login-page{
        background-image: url('/src/images/blue_background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex-direction: column;
        .logo-wrapper{
            a{
                display: block;
                width: 200px;
                height: auto;
            }
        }
        .MuiPaper-root{
            width: 40%;
            padding: 35px 65px;
            border-radius: 20px;
            .MuiTypography-root{
                font-weight: normal;
                @media screen and (max-width: $screen_540px){
                    font-size: 20px !important;
                }
            }
            .MuiFormLabel-root{
                font-size: 20px;
                font-weight: 400;
                color: $black;
                @media screen and (max-width: $screen_540px){
                    font-size: 16px !important;
                }
            }
            .MuiInputBase-root{
                .MuiInputBase-input{
                    padding: 6px 0 10px;
                }
            }
            .MuiGrid-root{
                .MuiButton-root{
                    border-radius: 10px;
                    padding: 16px;
                    margin: 0 auto;
                    width: 80%;
                    .MuiButton-label{
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
            .formControl__wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                .MuiFormControlLabel-root{
                    .MuiCheckbox-root{
                        margin: 0;
                        padding: 0 4px 0 10px;
                        .MuiIconButton-label{
                            .MuiSvgIcon-root{
                                width: 0.6em;
                                height: 0.6em;
                            }
                        }
                    }
                    .MuiFormControlLabel-label{
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
                .MuiTypography-root {
                    font-size: 12px;
                    color: $black;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            @media screen and (max-width: $screen_1280px){
                width: 50%;
            }
            @media screen and (max-width: $screen_1024px){
                width: 60%;
            }
            @media screen and (max-width: $screen_768px){
                width: 80%;
            }
            @media screen and (max-width: $screen_540px){
                width: 90%;
                padding: 20px;
            }
        }
    }
}