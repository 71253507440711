$max-width: 1440px;
$primary-font: 'Poppins', sans-serif !important;

.MuiMenuItem-root{
    font-family: $primary-font;
}

.header-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $max-width;
    margin: auto;
    padding: 0px 100px;
    background-color: $white;
    // height: 100px;
    .logo-wrapper{
        .logo-link{
            font-family: $primary-font;
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 54px;
            text-transform: capitalize;
            color: $black;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 120px;
            }
        }
    }
    .logout-btn-wrapper{
        .logout-btn{
            background: $blue;
            border-radius: 50px;
            padding: 12px 16px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;
            color: $white;
        }
    }

    @media screen and (max-width: $screen_1024px){
        padding: 8px 40px;
    }
    @media screen and (max-width: $screen_768px){
        padding: 8px 20px;
    }
    @media screen and (max-width: $screen_540px){
        padding: 8px;
        .logo-wrapper{
            .logo-link{
                font-size: 20px;
                line-height: 54px;
                img{
                    width: 100px;
                }
            }
        }
        .logout-btn-wrapper{
            .logout-btn{
                padding: 8px 12px;
                font-size: 14px;
            }
        }
    }
}

.main-form-wrapper{
    // background: #F3F5F8;
    background-image: url('/src/images/blue_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 0 40px 0;
    box-shadow: 5px 0 5px 5px #ccc;
    .form-wrapper{
        background: $white;
        border: 1px solid $white-border;
        border-radius: 10px;
        width: 60%;
        margin: auto;
        padding: 20px 80px;

        @media screen and (max-width: $screen_1024px){
            width: 85%;
        }
        @media screen and (max-width: $screen_768px){
            width: 90%;
        }
        @media screen and (max-width: $screen_540px){
            padding: 20px;
        }
        .heading-text{
            h2{
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 24px;                
                color: $black;
                text-align: center;
                // padding: 0 0 20px 0;
                // border-bottom: 1px solid $white-border;
            }
        }
        .form-fields-wrapper{
            > .MuiGrid-container{
                width: 100%;
                margin: 40px 0 0 0 !important;
                box-shadow: none;
                padding: 0;
                .MuiGrid-item{
                    padding: 0;
                }
                .content-wrapper{
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    // margin-bottom: 40px;
                    padding-bottom: 24px;
                    // border-bottom: 1px solid $white-border;
                    .profile-content-wrap{
                        width: 50%;
                        // padding-right: 24px;
                        // border-right: 1px solid $white-border;
                        .dropdown-label{
                            // margin-bottom: 30px;
                            .MuiFormControl-root{                                
                                .MuiFormLabel-root{
                                    left: 14px;
                                    top: -8px;
                                    font-family: $primary-font;
                                    z-index: 10;
                                    background: $white;
                                    display: none;
                                    &.Mui-focused{
                                        color: $blue;
                                    }
                                }
                                .MuiInputBase-root{
                                    // height: 50px;
                                    margin: 0;
                                    border: 1px solid rgba(0, 0, 0, 0.23);
                                    border-radius: 10px;
                                    margin-bottom: 10px;
                                    box-shadow: inset 0px -4px 2px 0px #ccc;
                                    padding: 10px 8px;
                                    &::before{
                                        display: none;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                    &.Mui-focused{
                                        // border: 1px solid $blue;
                                        border-radius: 10px;
                                        border: 1px solid rgba(0, 0, 0, 0.23);
                                    }
                                    .MuiSelect-root{
                                        padding: 0;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        &:focus{
                                            background-color: transparent;
                                        }
                                    }                                    
                                }
                            }
                        }
                        .upload-image-wrapper{
                            position: relative;
                            .MuiGrid-item{
                                .MuiTextField-root{
                                    position: unset;
                                    .MuiFormLabel-root{
                                        font-family: $primary-font;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: rgba(0, 0, 0, 0.5);
                                        transform: none;
                                        position: unset;
                                        text-align: left;
                                        margin-bottom: 24px;
                                    }
                                    .MuiInputBase-root{
                                        border: 0;
                                        padding: 0;
                                        position: absolute;
                                        left: 150px;
                                        top: 50%;
                                        .MuiInputBase-input{
                                            height: auto;
                                            padding: 0;
                                        }
                                        .MuiOutlinedInput-notchedOutline{
                                            display: none;
                                        }
                                        input[type=file]{
                                            color: transparent;
                                        }
                                        input[type=file]::file-selector-button{
                                            background-color: $white;
                                            border: 1px solid $white-border;
                                            border-radius: 3px;
                                            color: rgba(0, 0, 0, 0.5);
                                            font-family: $primary-font;
                                            padding: 10px 12px;
                                            border-radius: 3px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                            .upload-btn{
                                border: 1px solid rgba(0, 0, 0, 0.23);
                                border-radius: 10px;
                                box-shadow: inset 0px -4px 2px 0px #ccc;
                                padding: 10px 8px;
                                background: none;
                                width: 100%;
                                margin-bottom: 10px;
                                color: rgba(0, 0, 0, 0.54);
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1;
                                letter-spacing: 0.00938em;
                                cursor: pointer;
                            }
                        }
                        .input-field{
                            border-radius: 10px;
                            margin-bottom: 10px;
                            box-shadow: inset 0px -4px 2px 0px #ccc;
                            width: 100%;
                            padding: 10px 8px;
                            border: 1px solid rgba(0, 0, 0, 0.23);
                            text-align: center;
                            font-size: 16px;
                            &:focus{
                                border: 1px solid rgba(0, 0, 0, 0.23);
                                outline: 0;
                            }
                            &::placeholder{
                                text-align: center;
                                font-size: 16px;
                            }
                        }
                        @media screen and (max-width: $screen_768px){
                            width: 100%;
                            padding: 0;
                            border: 0;
                            margin-bottom: 10px;
                        }
                    }

                    @media screen and (max-width: $screen_768px){
                        flex-direction: column-reverse;
                    }
                    .slider-wrap{
                        width: 50%;
                        padding-left: 24px;
                        @media screen and (max-width: $screen_768px){
                            width: 100%;
                            padding: 0;
                            border: 0;
                            margin-bottom: 10px;
                        }
                        @media screen and (max-width: $screen_540px){
                            width: 100%;
                            padding: 0;
                            border: 0;
                            margin-bottom: 10px;
                        }
                    }
                }

                

                // .input-fields-wrapper{
                //     display: grid;
                //     grid-template-columns: 1fr 1fr;
                //     gap: 20px;
                //     .MuiGrid-root{
                //         max-width: 100%;
                //         flex-basis: 100%;
                //         .MuiFormControl-root {
                //             .MuiFormLabel-root{
                //                 font-family: $primary-font;
                //                 font-style: normal;
                //                 font-weight: 400;
                //                 font-size: 15px;
                //                 line-height: 22px;
                //                 color: rgba(0, 0, 0, 0.5);
                //                 top: -5px;
                //                 &.Mui-focused{
                //                     color: $blue;
                //                     top: 0;
                //                 }
                //             }
                //             .MuiOutlinedInput-root{
                //                 border-radius: 3px;
                //                 height: 50px;
                //                 input{
                //                     font-family: $primary-font;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     font-size: 16px;
                //                     line-height: 24px;
                //                     color: rgba(0, 0, 0, 0.8);
                //                     height: auto;
                //                 }
                //                 .MuiOutlinedInput-notchedOutline{
                //                     border-color: $white-border;
                //                 }
                //                 &:hover{
                //                     .MuiOutlinedInput-notchedOutline{
                //                         border-color: $blue;
                //                     }
                //                 }
                //                 &.Mui-focused{
                //                     .MuiOutlinedInput-notchedOutline{
                //                         border-color: $blue;
                //                     }
                //                 }
                //             }
                //         }

                //         &.textarea-field{
                //             .MuiFormControl-root{
                //                 .MuiInputBase-multiline{
                //                     height: auto !important;
                //                 }
                //             }
                //         }
                //     }
                //     @media screen and (max-width: $screen_768px){
                //         grid-template-columns: 1fr;
                //     }
                // }
                .MuiGrid-root{
                    text-align: left;
                    .MuiButtonBase-root{
                        // background: #3F51B5;
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 27px;
                        color: $white;
                        text-transform: capitalize;
                        width: 100%;
                        margin: 10px 0 0 0;
                        border-radius: 10px;
                        padding: 10px 8px;
                        .MuiButton-label{
                            font-family: $primary-font;
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 27px;
                            color: $white;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .media-link{
            text-align: left;
            padding-top: 24px;
            border-top: 1px solid $white-border;
            margin: 24px 0;
            b{
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.5);
            }
            a{  
                display: block;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $blue;
                margin-top: 16px;
            }
        }
    }
}

.modal-wrapper.upload-image-modal{
    .modal-body{
        width: 30%;
        border-radius: 20px;
        padding: 30px 20px;
        .modal-heading-wrapper{
            margin-bottom: 20px;
            text-align: center !important;
            input[type=file]{
                color: transparent;
                margin: auto;
                display: none;
            }
            // input[type=file]::file-selector-button{
            //     background-color: $white;
            //     border: 1px solid $white-border;
            //     border-radius: 3px;
            //     color: rgba(0, 0, 0, 0.5);
            //     font-family: $primary-font;
            //     padding: 10px 12px;
            //     border-radius: 3px;
            //     cursor: pointer;
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     justify-content: center;
            //     border-radius: 10px;
            //     margin-bottom: 10px;
            //     box-shadow: inset 0px -4px 2px 0px #ccc;
            //     border: 1px solid rgba(0, 0, 0, 0.23);
            //     width: 50%;
            // }
            // input[type="file" i] {
            //     text-align: center !important;
            // }

            .browse-btn{
                border-radius: 10px;
                margin-bottom: 10px;
                box-shadow: inset 0px -4px 2px 0px #ccc;
                border: 1px solid rgba(0, 0, 0, 0.23);
                font-family: $primary-font;
                font-size: 16px;
                font-weight: 400;
                color: #000;
                padding: 10px 40px;
                cursor: pointer;
            }
        }
        #modal-modal-description{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            .image-preview-wrapper{
                margin-bottom: 20px;
                padding: 20px;
                background-color: skyblue;
                border: 1px solid rgba(0, 0, 0, 0.23);
                border-radius: 20px;
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .img-wrap{
                    width: 80%;
                    height: 200px;
                    margin: auto;
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        
                    }
                    img[src=""]{
                        display: none;
                    }
                }
                button{
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    border-radius: 10px;
                    box-shadow: inset 0px -4px 2px 0px #ccc;
                    padding: 10px 35px;
                    background: #fff;
                    color: #000;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1;
                    letter-spacing: 0.00938em;
                    text-transform: capitalize;
                }
            }
            .blue-btn{
                font-family: "Poppins", sans-serif !important;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 27px;
                color: #FFFFFF;
                text-transform: capitalize;
                border-radius: 10px;
                padding: 10px 35px;
                background-color: #3f51b5;
            }
        }

        @media screen and (max-width: $screen_1024px){
            width: 50%;
        }
        @media screen and (max-width: $screen_768px){
            width: 80%;
        }
        @media screen and (max-width: $screen_540px){
            width: 90%;
            .modal-heading-wrapper{
                #modal-modal-title{
                    text-align: center !important;
                }
            }
        }
    }
}