.modal-heading-wrapper{
    .MuiIconButton-root{
        &.modal-close-btn{
            position: absolute;
            top: 15px;
            right: 15px;
            padding: 8px;
        }
    }

    @media screen and (max-width: $screen_540px){
        width: 100%;
        
        .MuiTypography-root{
            text-align: left !important;
            font-size: 1.2rem;
        }
        .MuiIconButton-root{
            &.modal-close-btn{
                top: 10px;
                right: 0px;
                padding: 6px;
            }
        }
    }
}

@media screen and (max-width: $screen_540px){
    #modal-modal-description{
        padding: 0;
    }
}

