@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Poppins', sans-serif !important;
    box-sizing: border-box;
}

body{
    padding: 0;
    margin: 0;
}

a{
    text-decoration: none;
}

button[aria-label='Export']{
    display: none !important; 
}

span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: red;
}
.MuiGridFilterForm-columnSelect {
    width: 170px !important;
}

.page-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    margin: 50px 0 40px 0;
    .page-title{
        h2{
            font-size: 2em;
            margin: 0;
        }
    }
    @media screen and (max-width: $screen_1280px){
        padding: 0 80px;
    }
    @media screen and (max-width: $screen_1024px){
        padding: 0 60px;
    }
    @media screen and (max-width: $screen_768px){
        padding: 0 40px;
    }
    @media screen and (max-width: $screen_540px){
        padding: 0 10px;
        flex-direction: column;
        margin: 20px 0 40px 0;
        .page-title{
            h2{
                font-size: 1.8em;
                margin: 0 0 20px 0;
            }
        }
    }
}
.page-title{
    h2{
        font-size: 2em;
        margin: 50px 0 40px 0;
    }
}

.footer-link{
    margin: 50px auto 0 auto;
    width: fit-content;
    a{
        font-size: 14px;
        color: #000;
        letter-spacing: 2px;
        width: fit-content;
        text-decoration: none;
        cursor: pointer;
        &::after{
            content:'';
            width: 100%;
            height: 1px;
            background-color: #000;
            display: block;
        }
        &:hover{
            text-decoration: none;
        }
    }
}

.heading2Xl {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingXl {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingLg {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.headingMd {
    font-size: 1.2rem;
    line-height: 1.5;
}

.borderCircle {
    border-radius: 9999px;
}

.colorInherit {
    color: inherit;
}

.padding1px {
    padding-top: 1px;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.listItem {
    margin: 0 0 1.25rem;
}

.lightText {
    color: #666;
}

.submitButton {
    color: white;
}

.headerlink {
    color: white;
    text-decoration: inherit;
}

.headerlink>a {
    color: white;
}

// img.image-gallery-image {
//     width: 400px;
//     max-width: 500px;
//     height: 400px;
// }

.modal-body {
    margin: 20%;
    width: 60%;
    /* padding: 20%; */
}

#modal-modal-description {
    padding: 2%;
    margin-top: 5px;
}


.MuiButtonBase-root{
    padding: 10px 24px;
}

.swal-overlay--show-modal{
    .swal-modal{
        .swal-footer{
            text-align: center;
        }
    }
}


.main-table-wrapper{
    padding: 0 100px;
    margin: 0 0 50px 0;

    > .MuiGrid-container{
        width: 100%;
        margin: auto;
    }

    .MuiDataGrid-root{
        box-shadow: 0 5px 10px 0 #ccc;
        border: 0;
        
        .MuiDataGrid-columnHeaderTitleContainer{
            padding: 0;
            .MuiDataGrid-columnHeaderTitle{
                font-weight: 700;
            }
        }
        .MuiDataGrid-menuIcon{
            margin-right: 0;
        }
        .MuiDataGrid-columnSeparator{
            right: 0;
        }
    }

    @media screen and (max-width: $screen_1280px){
        padding: 0 80px;
    }
    @media screen and (max-width: $screen_1024px){
        padding: 0 60px;
    }
    @media screen and (max-width: $screen_768px){
        padding: 0 40px;
    }
    @media screen and (max-width: $screen_540px){
        padding: 10px;

        > .MuiGrid-container{
            overflow-x: hidden;
            box-shadow: 0 5px 10px 0 #ccc;
            border: 0;
            border-radius: 4px;
        }
    }
}


// Modal Style //
.modal-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiPaper-root.modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    width: 30%;
    margin: auto;
    box-shadow: 0px 0px 4px 0px #212121;
    transform: translateY(-60%);
    animation-name: slide-down;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    @keyframes slide-down{
        from{
            transform: translateY(-60%);
        }
        to{
            transform: translateY(-10%);
        }
    }

    .MuiTypography-root{
        width: 100%;
        padding: 0;
        box-sizing: border-box;

        &.MuiTypography-h6{
            text-align: center;
            font-weight: 900;
        }

        p{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media screen and (max-width: $screen_1280px){
        width: 40%;
    }
    @media screen and (max-width: $screen_1024px){
        width: 50%;
    }
    @media screen and (max-width: $screen_768px){
        width: 65%;
    }
    @media screen and (max-width: $screen_540px){
        width: 90%;
        padding: 12px; 
    }
}

// Form Fields Style //
.form-wrapper{
    width: 85%;
    margin: auto;
    .MuiGrid-container{
        box-shadow: 0 0 2px 0 #ccc;
        padding: 40px 20px 20px 20px;
        margin-top: 50px;
        margin-bottom: 50px;
        .MuiGrid-item{
            .MuiFormControl-root{
                width: 100%;
                .MuiOutlinedInput-root{
                    // border-radius: 3px;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    box-shadow: inset 0px -4px 2px 0px #ccc;
                    .MuiOutlinedInput-input{
                        padding: 10px 8px;
                    }
                }
            }

            .MuiButtonBase-root{
                margin-top: 40px;
                padding: 10px 24px; 
            }
        }
    }

    .form-error{
        text-align: left;
        font-size: 14px;
    }

    @media screen and (max-width: $screen_1280px){
        width: 70%;
    }
    @media screen and (max-width: $screen_1024px){
        width: 80%;
    }
    @media screen and (max-width: $screen_768px){
        width: 85%;
    }
    @media screen and (max-width: $screen_540px){
        width: 90%;

        .MuiGrid-container{
            box-shadow: none;
            padding: 0;
            margin-top: 50px;
            flex-direction: column;
            margin-bottom: 0;
            .MuiGrid-item{
                max-width: 100%;
                .MuiFormControl-root{
                    width: 100%;
                }
    
                .MuiButtonBase-root{
                    margin-top: 20px;
                    padding: 10px 24px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: $screen_991px){
    .main-table-wrapper{
        .MuiDataGrid-root{
            .MuiDataGrid-footerContainer{
                justify-content: flex-start;
                .MuiTablePagination-root{
                    .MuiToolbar-root{
                        .MuiTablePagination-caption, .MuiTablePagination-input{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen_768px){
    .MuiBox-root{
        .MuiAppBar-root{
            .MuiToolbar-root{
                a{
                    font-size: 16px;
                    .MuiTypography-root{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: $screen_540px){ 
    .MuiBox-root{
        .MuiAppBar-root{
            .MuiToolbar-root{
                padding-left: 8px;
                padding-right: 8px;
                a{
                    font-size: 14px;
                    display: block;
                    font-weight: 500;
                    line-height: 22px;
                    .MuiTypography-root{
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .main-table-wrapper{
        .MuiDataGrid-root{
            .MuiDataGrid-footerContainer{
                .MuiDataGrid-selectedRowCount{
                    display: none;
                }
                .MuiTablePagination-root{
                    .MuiToolbar-root{
                        padding-left: 10px;
                        .MuiTablePagination-actions{
                            margin-left: 6px;
                            .MuiIconButton-root{
                                padding: 12px 2px; 
                            }
                        }

                        .MuiTablePagination-input{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
