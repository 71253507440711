// Colors //
$white: #FFFFFF;
$black: #000000;
$blue: #3F51B5;
$white-border: #E6E6E6;

// Screen Sizes //
$screen_1280px: 1280px;
$screen_1024px: 1024px;
$screen_991px: 991px;
$screen_768px: 768px;
$screen_540px: 540px;