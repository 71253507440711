@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

button[aria-label=Export] {
  display: none !important;
}

span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: red;
}

.MuiGridFilterForm-columnSelect {
  width: 170px !important;
}

.page-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  margin: 50px 0 40px 0;
}
.page-title-wrapper .page-title h2 {
  font-size: 2em;
  margin: 0;
}
@media screen and (max-width: 1280px) {
  .page-title-wrapper {
    padding: 0 80px;
  }
}
@media screen and (max-width: 1024px) {
  .page-title-wrapper {
    padding: 0 60px;
  }
}
@media screen and (max-width: 768px) {
  .page-title-wrapper {
    padding: 0 40px;
  }
}
@media screen and (max-width: 540px) {
  .page-title-wrapper {
    padding: 0 10px;
    flex-direction: column;
    margin: 20px 0 40px 0;
  }
  .page-title-wrapper .page-title h2 {
    font-size: 1.8em;
    margin: 0 0 20px 0;
  }
}

.page-title h2 {
  font-size: 2em;
  margin: 50px 0 40px 0;
}

.footer-link {
  margin: 50px auto 0 auto;
  width: fit-content;
}
.footer-link a {
  font-size: 14px;
  color: #000;
  letter-spacing: 2px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
}
.footer-link a::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
}
.footer-link a:hover {
  text-decoration: none;
}

.heading2Xl {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0;
}

.headingXl {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0;
}

.headingLg {
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 1rem 0;
}

.headingMd {
  font-size: 1.2rem;
  line-height: 1.5;
}

.borderCircle {
  border-radius: 9999px;
}

.colorInherit {
  color: inherit;
}

.padding1px {
  padding-top: 1px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  margin: 0 0 1.25rem;
}

.lightText {
  color: #666;
}

.submitButton {
  color: white;
}

.headerlink {
  color: white;
  text-decoration: inherit;
}

.headerlink > a {
  color: white;
}

.modal-body {
  margin: 20%;
  width: 60%;
  /* padding: 20%; */
}

#modal-modal-description {
  padding: 2%;
  margin-top: 5px;
}

.MuiButtonBase-root {
  padding: 10px 24px;
}

.swal-overlay--show-modal .swal-modal .swal-footer {
  text-align: center;
}

.main-table-wrapper {
  padding: 0 100px;
  margin: 0 0 50px 0;
}
.main-table-wrapper > .MuiGrid-container {
  width: 100%;
  margin: auto;
}
.main-table-wrapper .MuiDataGrid-root {
  box-shadow: 0 5px 10px 0 #ccc;
  border: 0;
}
.main-table-wrapper .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0;
}
.main-table-wrapper .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}
.main-table-wrapper .MuiDataGrid-root .MuiDataGrid-menuIcon {
  margin-right: 0;
}
.main-table-wrapper .MuiDataGrid-root .MuiDataGrid-columnSeparator {
  right: 0;
}
@media screen and (max-width: 1280px) {
  .main-table-wrapper {
    padding: 0 80px;
  }
}
@media screen and (max-width: 1024px) {
  .main-table-wrapper {
    padding: 0 60px;
  }
}
@media screen and (max-width: 768px) {
  .main-table-wrapper {
    padding: 0 40px;
  }
}
@media screen and (max-width: 540px) {
  .main-table-wrapper {
    padding: 10px;
  }
  .main-table-wrapper > .MuiGrid-container {
    overflow-x: hidden;
    box-shadow: 0 5px 10px 0 #ccc;
    border: 0;
    border-radius: 4px;
  }
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiPaper-root.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  width: 30%;
  margin: auto;
  box-shadow: 0px 0px 4px 0px #212121;
  transform: translateY(-60%);
  animation-name: slide-down;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes slide-down {
  from {
    transform: translateY(-60%);
  }
  to {
    transform: translateY(-10%);
  }
}
.MuiPaper-root.modal-body .MuiTypography-root {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}
.MuiPaper-root.modal-body .MuiTypography-root.MuiTypography-h6 {
  text-align: center;
  font-weight: 900;
}
.MuiPaper-root.modal-body .MuiTypography-root p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1280px) {
  .MuiPaper-root.modal-body {
    width: 40%;
  }
}
@media screen and (max-width: 1024px) {
  .MuiPaper-root.modal-body {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .MuiPaper-root.modal-body {
    width: 65%;
  }
}
@media screen and (max-width: 540px) {
  .MuiPaper-root.modal-body {
    width: 90%;
    padding: 12px;
  }
}

.form-wrapper {
  width: 85%;
  margin: auto;
}
.form-wrapper .MuiGrid-container {
  box-shadow: 0 0 2px 0 #ccc;
  padding: 40px 20px 20px 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.form-wrapper .MuiGrid-container .MuiGrid-item .MuiFormControl-root {
  width: 100%;
}
.form-wrapper .MuiGrid-container .MuiGrid-item .MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
}
.form-wrapper .MuiGrid-container .MuiGrid-item .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
  padding: 10px 8px;
}
.form-wrapper .MuiGrid-container .MuiGrid-item .MuiButtonBase-root {
  margin-top: 40px;
  padding: 10px 24px;
}
.form-wrapper .form-error {
  text-align: left;
  font-size: 14px;
}
@media screen and (max-width: 1280px) {
  .form-wrapper {
    width: 70%;
  }
}
@media screen and (max-width: 1024px) {
  .form-wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .form-wrapper {
    width: 85%;
  }
}
@media screen and (max-width: 540px) {
  .form-wrapper {
    width: 90%;
  }
  .form-wrapper .MuiGrid-container {
    box-shadow: none;
    padding: 0;
    margin-top: 50px;
    flex-direction: column;
    margin-bottom: 0;
  }
  .form-wrapper .MuiGrid-container .MuiGrid-item {
    max-width: 100%;
  }
  .form-wrapper .MuiGrid-container .MuiGrid-item .MuiFormControl-root {
    width: 100%;
  }
  .form-wrapper .MuiGrid-container .MuiGrid-item .MuiButtonBase-root {
    margin-top: 20px;
    padding: 10px 24px;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer {
    justify-content: flex-start;
  }
  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-caption, .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-input {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .MuiBox-root .MuiAppBar-root .MuiToolbar-root a {
    font-size: 16px;
  }
  .MuiBox-root .MuiAppBar-root .MuiToolbar-root a .MuiTypography-root {
    font-size: 16px;
  }
}
@media screen and (max-width: 540px) {
  .MuiBox-root .MuiAppBar-root .MuiToolbar-root {
    padding-left: 8px;
    padding-right: 8px;
  }
  .MuiBox-root .MuiAppBar-root .MuiToolbar-root a {
    font-size: 14px;
    display: block;
    font-weight: 500;
    line-height: 22px;
  }
  .MuiBox-root .MuiAppBar-root .MuiToolbar-root a .MuiTypography-root {
    font-size: 14px;
  }

  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
    display: none;
  }
  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root {
    padding-left: 10px;
  }
  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-actions {
    margin-left: 6px;
  }
  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-actions .MuiIconButton-root {
    padding: 12px 2px;
  }
  .main-table-wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-input {
    margin-right: 10px;
  }
}
.login-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page-wrapper .MuiPaper-root {
  width: 25%;
  height: auto;
  padding: 35px;
  box-sizing: border-box;
  box-shadow: 0 0 2px 0 #ccc;
}
.login-page-wrapper .MuiPaper-root .MuiTypography-root {
  font-weight: bold;
}
.login-page-wrapper .MuiPaper-root br {
  display: none;
}
.login-page-wrapper .MuiPaper-root .MuiGrid-root {
  width: 100%;
  margin: 0;
  padding: 0;
}
.login-page-wrapper .MuiPaper-root .MuiGrid-root .MuiGrid-item {
  margin-top: 30px;
}
.login-page-wrapper .MuiPaper-root .MuiGrid-root .MuiGrid-item .MuiFormControl-root {
  width: 100%;
}
.login-page-wrapper .MuiPaper-root .MuiGrid-root .MuiGrid-item .MuiButtonBase-root {
  padding: 10px 16px;
  margin-top: 30px;
}
@media screen and (max-width: 1280px) {
  .login-page-wrapper .MuiPaper-root {
    width: 30%;
  }
}
@media screen and (max-width: 1024px) {
  .login-page-wrapper .MuiPaper-root {
    width: 40%;
  }
}
@media screen and (max-width: 768px) {
  .login-page-wrapper .MuiPaper-root {
    width: 60%;
  }
}
@media screen and (max-width: 540px) {
  .login-page-wrapper .MuiPaper-root {
    width: 90%;
  }
}
.login-page-wrapper.user-login-page, .login-page-wrapper.admin-login-page {
  background-image: url("/src/images/blue_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
}
.login-page-wrapper.user-login-page .logo-wrapper a, .login-page-wrapper.admin-login-page .logo-wrapper a {
  display: block;
  width: 200px;
  height: auto;
}
.login-page-wrapper.user-login-page .MuiPaper-root, .login-page-wrapper.admin-login-page .MuiPaper-root {
  width: 40%;
  padding: 35px 65px;
  border-radius: 20px;
}
.login-page-wrapper.user-login-page .MuiPaper-root .MuiTypography-root, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiTypography-root {
  font-weight: normal;
}
@media screen and (max-width: 540px) {
  .login-page-wrapper.user-login-page .MuiPaper-root .MuiTypography-root, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiTypography-root {
    font-size: 20px !important;
  }
}
.login-page-wrapper.user-login-page .MuiPaper-root .MuiFormLabel-root, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiFormLabel-root {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}
@media screen and (max-width: 540px) {
  .login-page-wrapper.user-login-page .MuiPaper-root .MuiFormLabel-root, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiFormLabel-root {
    font-size: 16px !important;
  }
}
.login-page-wrapper.user-login-page .MuiPaper-root .MuiInputBase-root .MuiInputBase-input, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiInputBase-root .MuiInputBase-input {
  padding: 6px 0 10px;
}
.login-page-wrapper.user-login-page .MuiPaper-root .MuiGrid-root .MuiButton-root, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiGrid-root .MuiButton-root {
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;
  width: 80%;
}
.login-page-wrapper.user-login-page .MuiPaper-root .MuiGrid-root .MuiButton-root .MuiButton-label, .login-page-wrapper.admin-login-page .MuiPaper-root .MuiGrid-root .MuiButton-root .MuiButton-label {
  font-size: 20px;
  font-weight: bold;
}
.login-page-wrapper.user-login-page .MuiPaper-root .formControl__wrapper, .login-page-wrapper.admin-login-page .MuiPaper-root .formControl__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.login-page-wrapper.user-login-page .MuiPaper-root .formControl__wrapper .MuiFormControlLabel-root .MuiCheckbox-root, .login-page-wrapper.admin-login-page .MuiPaper-root .formControl__wrapper .MuiFormControlLabel-root .MuiCheckbox-root {
  margin: 0;
  padding: 0 4px 0 10px;
}
.login-page-wrapper.user-login-page .MuiPaper-root .formControl__wrapper .MuiFormControlLabel-root .MuiCheckbox-root .MuiIconButton-label .MuiSvgIcon-root, .login-page-wrapper.admin-login-page .MuiPaper-root .formControl__wrapper .MuiFormControlLabel-root .MuiCheckbox-root .MuiIconButton-label .MuiSvgIcon-root {
  width: 0.6em;
  height: 0.6em;
}
.login-page-wrapper.user-login-page .MuiPaper-root .formControl__wrapper .MuiFormControlLabel-root .MuiFormControlLabel-label, .login-page-wrapper.admin-login-page .MuiPaper-root .formControl__wrapper .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px;
  line-height: 12px;
}
.login-page-wrapper.user-login-page .MuiPaper-root .formControl__wrapper .MuiTypography-root, .login-page-wrapper.admin-login-page .MuiPaper-root .formControl__wrapper .MuiTypography-root {
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 1280px) {
  .login-page-wrapper.user-login-page .MuiPaper-root, .login-page-wrapper.admin-login-page .MuiPaper-root {
    width: 50%;
  }
}
@media screen and (max-width: 1024px) {
  .login-page-wrapper.user-login-page .MuiPaper-root, .login-page-wrapper.admin-login-page .MuiPaper-root {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .login-page-wrapper.user-login-page .MuiPaper-root, .login-page-wrapper.admin-login-page .MuiPaper-root {
    width: 80%;
  }
}
@media screen and (max-width: 540px) {
  .login-page-wrapper.user-login-page .MuiPaper-root, .login-page-wrapper.admin-login-page .MuiPaper-root {
    width: 90%;
    padding: 20px;
  }
}

.form-wrapper .image-preview-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ccc;
}
.form-wrapper .image-preview-wrapper b {
  display: none;
}
.form-wrapper .image-preview-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.form-wrapper .image-preview-wrapper img[src=""] {
  display: none;
}
.form-wrapper .image-gallery-wrapper b {
  font-family: "Poppins", sans-serif !important;
}
.form-wrapper .image-gallery-wrapper .image-gallery {
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding-bottom: 10px;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-icon {
  padding: 0;
  margin: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(131, 131, 131, 0.15);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  bottom: -68px !important;
  filter: none;
  display: flex;
  align-items: center;
  justify-content: center;
  top: unset;
  transform: none;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-icon svg {
  width: 75%;
  height: 75%;
  color: rgba(0, 0, 0, 0.4);
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-icon:hover {
  color: #fff;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-icon:hover svg {
  transform: none;
}
@media screen and (max-width: 540px) {
  .form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-icon {
    width: 40px;
    height: 40px;
  }
  .form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-icon svg {
    width: 85%;
    height: 85%;
  }
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slides {
  margin-bottom: 20px;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slides .image-gallery-slide h3 {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  margin: 0 0 30px 0;
  border-radius: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  padding: 10px 8px;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  display: flex;
  justify-content: center;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slides .image-gallery-slide h3 span {
  flex: 0 0 85px;
  text-align: left;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slides .image-gallery-slide h3 a {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slides .image-gallery-slide .image-gallery-image {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
@media screen and (max-width: 540px) {
  .form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-slides .image-gallery-slide .image-gallery-image {
    width: 100%;
    height: 100px;
  }
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  padding: 8px;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail .image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail .image-gallery-thumbnail-inner img {
  width: 80%;
  height: 80%;
  object-fit: fill;
}
.form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail.active, .form-wrapper .image-gallery-wrapper .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail:hover {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.form-wrapper .media-link {
  margin: 25px 0;
}
.form-wrapper .media-link a {
  word-wrap: break-word;
}

@media screen and (max-width: 540px) {
  div[role=tooltip] {
    transform: translate3d(10px, 369px, 0px) !important;
    width: 94.5%;
  }
  div[role=tooltip] .MuiGridPanel-paper .MuiGridPanelWrapper-root .MuiGridPanelContent-root .MuiGridFilterForm-root {
    flex-direction: column;
  }
  div[role=tooltip] .MuiGridPanel-paper .MuiGridPanelWrapper-root .MuiGridPanelContent-root .MuiGridFilterForm-root > .MuiFormControl-root {
    width: 100% !important;
    margin-bottom: 10px;
  }
  div[role=tooltip] .MuiGridPanel-paper .MuiGridPanelWrapper-root .MuiGridPanelContent-root .MuiGridFilterForm-root > .MuiFormControl-root .MuiIconButton-label {
    justify-content: flex-end;
  }
  div[role=tooltip] .MuiGridPanel-paper .MuiGridPanelWrapper-root .MuiGridPanelContent-root .MuiGridFilterForm-root > .MuiFormControl-root.MuiGridFilterForm-closeIcon {
    margin: 0;
  }
}

@media screen and (max-width: 540px) {
  .download-btn {
    font-size: 12px !important;
    padding: 4px 6px !important;
  }
}

.modal-heading-wrapper .MuiIconButton-root.modal-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 8px;
}
@media screen and (max-width: 540px) {
  .modal-heading-wrapper {
    width: 100%;
  }
  .modal-heading-wrapper .MuiTypography-root {
    text-align: left !important;
    font-size: 1.2rem;
  }
  .modal-heading-wrapper .MuiIconButton-root.modal-close-btn {
    top: 10px;
    right: 0px;
    padding: 6px;
  }
}

@media screen and (max-width: 540px) {
  #modal-modal-description {
    padding: 0;
  }
}
.MuiMenuItem-root {
  font-family: "Poppins", sans-serif !important;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin: auto;
  padding: 0px 100px;
  background-color: #FFFFFF;
}
.header-wrapper .logo-wrapper .logo-link {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 54px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-wrapper .logo-wrapper .logo-link img {
  width: 120px;
}
.header-wrapper .logout-btn-wrapper .logout-btn {
  background: #3F51B5;
  border-radius: 50px;
  padding: 12px 16px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #FFFFFF;
}
@media screen and (max-width: 1024px) {
  .header-wrapper {
    padding: 8px 40px;
  }
}
@media screen and (max-width: 768px) {
  .header-wrapper {
    padding: 8px 20px;
  }
}
@media screen and (max-width: 540px) {
  .header-wrapper {
    padding: 8px;
  }
  .header-wrapper .logo-wrapper .logo-link {
    font-size: 20px;
    line-height: 54px;
  }
  .header-wrapper .logo-wrapper .logo-link img {
    width: 100px;
  }
  .header-wrapper .logout-btn-wrapper .logout-btn {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.main-form-wrapper {
  background-image: url("/src/images/blue_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px 0 40px 0;
  box-shadow: 5px 0 5px 5px #ccc;
}
.main-form-wrapper .form-wrapper {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  width: 60%;
  margin: auto;
  padding: 20px 80px;
}
@media screen and (max-width: 1024px) {
  .main-form-wrapper .form-wrapper {
    width: 85%;
  }
}
@media screen and (max-width: 768px) {
  .main-form-wrapper .form-wrapper {
    width: 90%;
  }
}
@media screen and (max-width: 540px) {
  .main-form-wrapper .form-wrapper {
    padding: 20px;
  }
}
.main-form-wrapper .form-wrapper .heading-text h2 {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container {
  width: 100%;
  margin: 40px 0 0 0 !important;
  box-shadow: none;
  padding: 0;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .MuiGrid-item {
  padding: 0;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 24px;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap {
  width: 50%;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiFormLabel-root {
  left: 14px;
  top: -8px;
  font-family: "Poppins", sans-serif !important;
  z-index: 10;
  background: #FFFFFF;
  display: none;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  color: #3F51B5;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiInputBase-root {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  padding: 10px 8px;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiInputBase-root::before {
  display: none;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiInputBase-root::after {
  display: none;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiInputBase-root.Mui-focused {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiInputBase-root .MuiSelect-root {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .dropdown-label .MuiFormControl-root .MuiInputBase-root .MuiSelect-root:focus {
  background-color: transparent;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper {
  position: relative;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root {
  position: unset;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root .MuiFormLabel-root {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  transform: none;
  position: unset;
  text-align: left;
  margin-bottom: 24px;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root .MuiInputBase-root {
  border: 0;
  padding: 0;
  position: absolute;
  left: 150px;
  top: 50%;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root .MuiInputBase-root .MuiInputBase-input {
  height: auto;
  padding: 0;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  display: none;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root .MuiInputBase-root input[type=file] {
  color: transparent;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .MuiGrid-item .MuiTextField-root .MuiInputBase-root input[type=file]::file-selector-button {
  background-color: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif !important;
  padding: 10px 12px;
  border-radius: 3px;
  cursor: pointer;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .upload-image-wrapper .upload-btn {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  padding: 10px 8px;
  background: none;
  width: 100%;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  cursor: pointer;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .input-field {
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  width: 100%;
  padding: 10px 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  text-align: center;
  font-size: 16px;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .input-field:focus {
  border: 1px solid rgba(0, 0, 0, 0.23);
  outline: 0;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap .input-field::placeholder {
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .profile-content-wrap {
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper {
    flex-direction: column-reverse;
  }
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .slider-wrap {
  width: 50%;
  padding-left: 24px;
}
@media screen and (max-width: 768px) {
  .main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .slider-wrap {
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 540px) {
  .main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .content-wrapper .slider-wrap {
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 10px;
  }
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .MuiGrid-root {
  text-align: left;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .MuiGrid-root .MuiButtonBase-root {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
  text-transform: capitalize;
  width: 100%;
  margin: 10px 0 0 0;
  border-radius: 10px;
  padding: 10px 8px;
}
.main-form-wrapper .form-wrapper .form-fields-wrapper > .MuiGrid-container .MuiGrid-root .MuiButtonBase-root .MuiButton-label {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
  text-transform: capitalize;
}
.main-form-wrapper .form-wrapper .media-link {
  text-align: left;
  padding-top: 24px;
  border-top: 1px solid #E6E6E6;
  margin: 24px 0;
}
.main-form-wrapper .form-wrapper .media-link b {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}
.main-form-wrapper .form-wrapper .media-link a {
  display: block;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3F51B5;
  margin-top: 16px;
}

.modal-wrapper.upload-image-modal .modal-body {
  width: 30%;
  border-radius: 20px;
  padding: 30px 20px;
}
.modal-wrapper.upload-image-modal .modal-body .modal-heading-wrapper {
  margin-bottom: 20px;
  text-align: center !important;
}
.modal-wrapper.upload-image-modal .modal-body .modal-heading-wrapper input[type=file] {
  color: transparent;
  margin: auto;
  display: none;
}
.modal-wrapper.upload-image-modal .modal-body .modal-heading-wrapper .browse-btn {
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  padding: 10px 40px;
  cursor: pointer;
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description .image-preview-wrapper {
  margin-bottom: 20px;
  padding: 20px;
  background-color: skyblue;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description .image-preview-wrapper .img-wrap {
  width: 80%;
  height: 200px;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description .image-preview-wrapper .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description .image-preview-wrapper .img-wrap img[src=""] {
  display: none;
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description .image-preview-wrapper button {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  box-shadow: inset 0px -4px 2px 0px #ccc;
  padding: 10px 35px;
  background: #fff;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.00938em;
  text-transform: capitalize;
}
.modal-wrapper.upload-image-modal .modal-body #modal-modal-description .blue-btn {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 10px 35px;
  background-color: #3f51b5;
}
@media screen and (max-width: 1024px) {
  .modal-wrapper.upload-image-modal .modal-body {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .modal-wrapper.upload-image-modal .modal-body {
    width: 80%;
  }
}
@media screen and (max-width: 540px) {
  .modal-wrapper.upload-image-modal .modal-body {
    width: 90%;
  }
  .modal-wrapper.upload-image-modal .modal-body .modal-heading-wrapper #modal-modal-title {
    text-align: center !important;
  }
}


